import { createMuiTheme } from '@material-ui/core/styles'
import { heading } from '../../utils/utils'

const bodyFont = 'GT Pressura, sans-serif'
const headingFont = 'GT Pressura Mono, sans-serif'

const white = '#fff'
const black = '#13100D'
const primaryMain = black
const red = '#E40421'
const grey = '#606060'
const greyLight = '#F3F3F3'
const blue = '#0C85D1'

const green = '#91AC5E'
//const yellow = '#EFCF4B'
//const orange = '#D4833A'

const imp = '!important'

const f14 = `14px`
const f15 = `15px`
const f16 = `16px`
const f18 = `18px`
const f22 = `22px`
const f24 = `24px`
const f35 = `35px`
const f55 = `55px`

const body = {
  fontSize: f14,
  fontWeight: 400,
  lineHeight: 1.5,
  '@media (min-width: 1024px)': {
    fontSize: f16,
  },
}

const customSelect = {
  fontSize: `14px ${imp}`,
  borderRadius: `6px ${imp}`,
  border: `1px solid ${greyLight} ${imp}`,
  padding: `7px 10px ${imp}`,
  background: `${greyLight} ${imp}`,
  '&:focus': {
    outline: `none ${imp}`,
  },
}

const theme = createMuiTheme({
  themeFont: {
    main: bodyFont,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: `${bodyFont}, sans-serif`,
    h1: {
      ...heading(f35, f55, 700, 700, headingFont, headingFont, false),
      textAlign: `center`,
    },
    h2: {
      ...heading(f35, f24, 600, 700, headingFont, headingFont, false),
    },
    h3: {
      ...heading(f24, f24, 600, 600, headingFont, headingFont, false),
    },
    h4: {
      ...heading(f22, f22, 700, 700, headingFont, headingFont, false),
    },
    h5: {
      ...heading(f14, f14, 600, 600, headingFont, headingFont, false),
    },
    h6: {
      ...heading(f18, f18, 400, 400, headingFont, headingFont, false),
    },
    body1: {
      ...body,
    },
    body2: {
      ...body,
    },
    caption: {
      fontSize: f15,
    },
    button: {
      fontSize: f14,
      lineHeight: 1,
      fontWeight: 600,
      fontFamily: bodyFont,
      '@media (min-width: 1024px)': {
        fontSize: f16,
      },
    },
  },
  palette: {
    common: {
      black: black, // Refer to Text color in Figma
      white: white,
    },
    background: {
      default: white,
      paper: white,
    },
    primary: {
      main: primaryMain,
    },
    secondary: {
      main: primaryMain,
    },
    success: {
      main: green,
    },
    error: {
      main: red,
    },
    warning: {
      main: '#FF8333',
    },
    divider: '#f0f0f0',
    primaryTransparent: {
      main: primaryMain,
      light: greyLight,
    },
    secondaryTransparent: {
      main: primaryMain,
      light: greyLight,
    },
    ternary: {
      main: blue,
      light: '#F1FAFF',
    },
    accent: {
      main: primaryMain,
    },
  },
  sizes: {
    headerHeight: 60,
    headerHeightTransparent: 90,
    logoWidth: 110,
    logoHeight: 30,
    footerBG: 168,
    basketWidth: 340,
    basketWidthLG: 440,
  },
  extraColors: {
    grey: grey,
    greyLight: greyLight,
    greyMedium: '#C0BCC6',
    greyText: grey,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 8,
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.banner': {
          '&>.MuiTypography-h5': {
            fontFamily: `${bodyFont} ${imp}`,
          },
        },
        '.header': {
          '&>.MuiContainer-root': {
            paddingRight: '0',
            '@media (min-width: 1440px)': {
              maxWidth: '100%',
            },
          },
          '&__logo': {
            height: `50px ${imp}`,
          },
          '&__layout': {
            height: `80px ${imp}`,
          },
          '&__icons': {
            '& .MuiButtonBase-root': {
              fontSize: `${f16} ${imp}`,
            },
            '& .MuiSvgIcon-root': {
              fill: black,
            },
          },
          '& .menu': {
            '&--primary': {
              '& .menu__list--primary': {
                '& .menu__item': {
                  '&:after': {
                    content: 'none',
                  },
                },
              },
            },
            '&__list': {
              gap: `16px ${imp}`,
            },
          },
          '& .basket': {
            backgroundColor: `${blue} ${imp}`,
            marginLeft: '2rem',
            height: '100%',
            width: '5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          '&__cta--instore--choice': {
            color: `${black} ${imp}`,
          },
        },
        '.cart-badge': {
          borderRadius: `10px ${imp}`,
        },
        '.shop__planner': {
          '@media (max-width: 480px)': {
            marginTop: `-100px ${imp}`,
          },
        },
        '.shipping-infos': {
          '@media (max-width: 768px)': {
            paddingTop: `10px ${imp}`,
          },
        },
        '.select': {
          ...customSelect,
        },
        '.faq__icon--svg, .account__icon--svg': {
          display: `inherit ${imp}`,
          color: `${black} ${imp}`,
          '&>span': {
            '&>svg': {
              fontSize: `21px ${imp}`,
            },
          },
        },
        '.hero__home': {
          minHeight: `inherit ${imp}`,
          height: `60vh ${imp}`,
          '& .daterange': {
            border: '0!important',
          },
          '& .title': {
            fontSize: `36px ${imp}`,
            textAlign: `left ${imp}`,
          },
          '& .tagline': {
            textAlign: `left ${imp}`,
            fontFamily: `${bodyFont} ${imp}`,
          },
        },
        '.daterange': {
          border: `1px solid ${black} !important`,
        },
        '.hero__title': {
          fontSize: `55px ${imp}`,
          '@media (max-width: 480px)': {
            marginTop: `32px ${imp}`,
          },
          '@media (max-width: 768px)': {
            fontSize: `35px ${imp}`,
          },
        },
        '.discover': {
          '&__card, &__shadow': {
            display: 'flex',
            flexDirection: 'column-reverse',
          },
          '&__title': {
            position: `absolute ${imp}`,
            zIndex: `2 ${imp}`,
            marginLeft: `3px ${imp}`,
            textAlign: `left ${imp}`,
            color: `${white} ${imp}`,
            padding: `8px 0 ${imp}`,
            fontSize: `23px ${imp}`,
            backgroundColor: `transparent ${imp}`,
          },
        },
        '.section-discover': {
          '& .MuiContainer-root': {
            maxWidth: `inherit ${imp}`,
          },
          '&__subtitle': {
            fontSize: `40px ${imp}`,
          },
        },
        '.discover-product': {
          '&__content': {
            position: `absolute ${imp}`,
            zIndex: `2 ${imp}`,
            marginLeft: `8px ${imp}`,
            textAlign: `left ${imp}`,
            color: `${white} ${imp}`,
            padding: `8px 0 ${imp}`,
            backgroundColor: `transparent ${imp}`,
          },
          '&__title': {
            color: `${white} ${imp}`,
            fontSize: `23px ${imp}`,
            '@media (max-width: 768px)': {
              fontSize: `20px ${imp}`,
            },
          },
        },
        '.section-hiw ': {
          backgroundColor: `${greyLight} ${imp}`,
          '@media (max-width: 768px)': {
            maxWidth: `80% ${imp}`,
            margin: `auto ${imp}`,
          },
          '& .MuiContainer-root': {
            paddingLeft: `0 ${imp}`,
            paddingRight: `0 ${imp}`,
            maxWidth: `1360px ${imp}`,
          },
          '& .MuiGrid-grid-md-4': {
            '@media (min-width: 768px)': {
              flexBasis: `${100 / 4}% ${imp}`,
              maxWidth: `${100 / 4}% ${imp}`,
            },
          },
          '&__title': {},
        },
        '.section-hiw__title': {
          '&.MuiTypography-h2': {
            '@media (min-width: 1024px)': {
              fontSize: `55px ${imp}`,
            },
          },
        },
        '.hiw': {
          '&__icon': {
            '&>img': {
              width: `85px ${imp}`,
            },
          },
          '&__title': {
            fontSize: `32px ${imp}`,
          },
          '&__desc': {
            fontSize: `16px ${imp}`,
            '@media (max-width: 768px)': {
              fontSize: `14px ${imp}`,
            },
          },
        },
        '.productCard': {
          '&__title': {
            fontFamily: `${bodyFont} ${imp}`,
            paddingLeft: `18px ${imp}`,
            paddingRight: `28px ${imp}`,
          },
          '&__cta': {
            color: `${white} !important`,
          },
          backgroundColor: `#F4F4F4 ${imp}`,
        },
        '.packCard-title': {
          '&__availability__pack-expedition-mixte': {
            display: `none ${imp}`,
          },
          '&__price__pack-expedition-mixte': {
            display: `none ${imp}`,
          },
        },
        '.pack-product': {
          backgroundColor: `#F4F4F4 ${imp}`,
        },
        '.section-discover__footer': {
          marginTop: `40px ${imp}`,
          marginBottom: `40px ${imp}`,
        },
        '.faq': {
          '&__item': {
            marginBottom: '0!important',
            '&:last-child': {
              marginBottom: '32px !important',
              '@media (min-width: 1024px)': {
                marginBottom: '64px !important',
              },
            },
          },
          '&__question': {
            fontSize: '16px !important',
            borderBottomColor: `${grey} !important`,
            marginBottom: '0!important',
            '&[aria-expanded=true]': {},
          },
          '&__btn': {
            border: '0!important',
          },
          '&__answer': {},
          '&-content': {
            maxWidth: '1024px !important',
            '@media (min-width: 1024px)': {
              paddingBottom: '50px !important',
            },
          },
          '&-page': {
            background: '#F4F4F4 !important',
            '&__cat': {
              textTransform: 'none!important',
              '@media (min-width: 1024px)': {
                margin: '50px 0 !important',
              },
            },
          },
        },
        '.product': {
          '&__head': {
            flexDirection: `column ${imp}`,
            alignItems: `flex-start ${imp}`,
          },
          '&__title': {
            fontSize: `30px ${imp}`,
          },
          '&__price': {
            fontSize: `25px ${imp}`,
          },
        },
        '.pack': {
          '&__head': {
            flexDirection: `column ${imp}`,
            alignItems: `flex-start ${imp}`,
          },
          '&__title': {
            fontSize: `30px ${imp}`,
          },
          '&__price': {
            fontSize: `25px ${imp}`,
            '&__pack-expedition-mixte': {
              display: 'none',
            },
          },
          '&__unavailable__pack-expedition-mixte': {
            display: 'none',
          },
        },
        '.commonInformations__pack-expedition-mixte': {
          display: `none ${imp}`,
        },
        '.quantitySelector': {
          '&__btn': {
            '&--minus': {
              '& .MuiButton-label': {
                color: `${primaryMain} ${imp}`,
              },
            },
          },
        },
        '.react-datepicker-wrapper': {
          '& .MuiButton-label': {
            color: `${primaryMain} ${imp}`,
          },
        },
        '.product__bottom-item': {
          fontSize: `inherit ${imp}`,
        },
        '.page__container': {
          '&> h1': {},
        },
        '.warning-adress_cta': {
          color: `${black} ${imp}`,
        },
        '.footer__bottomRow2': {
          marginTop: `-20px ${imp}`,
        },
        '.reset__button': {
          color: `${black} ${imp}`,
        },
      },
    },
    MuiButton: {
      root: {
        padding: '15px 45px',
        borderRadius: 0,
        fontWeight: 400,
        textTransform: 'none',
      },
      label: {
        // color: white,
      },
      sizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
        fontSize: f16,
      },
      sizeLarge: {
        padding: '12px 45px',
        fontSize: f16,
      },
      contained: {
        boxShadow: 'none',
        '&:hover, &:focus, &:active, &.selected': {
          boxShadow: 'none',
        },
      },
      outlined: {
        padding: '12px 45px',
      },
      outlinedSizeLarge: {
        padding: '12px 45px',
      },
      outlinedSizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
      },
    },
    MuiPaper: {
      root: {
        boxShadow: '0px 0px 15px rgba(46, 46, 46, 0.1)',
      },
      rounded: {
        borderRadius: 0,
      },
    },
    MuiSelect: {
      root: {
        color: black,
      },
      selectMenu: {
        '&:focus': {
          background: '#fff',
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: 0,
        fontSize: 15,
        fontWeight: 700,
        color: '#000',
        backgroundColor: 'transparent',
        height: 25,
        padding: 0,
      },
      label: {
        padding: 0,
      },
      sizeSmall: {
        height: 22,
        fontSize: f15,
        fontWeight: 400,
      },
      labelSmall: {
        paddingLeft: f15,
        paddingRight: 10,
      },
      colorPrimary: {
        color: primaryMain,
        backgroundColor: 'transparent',
      },
      colorSecondary: {
        color: primaryMain,
        backgroundColor: 'transparent',
      },
    },
  },
})

export default theme
